/** @jsx jsx */
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import {
  Button,
  Container,
  Heading,
  Link,
  UserGeneratedHtml,
} from "@trueskin-web/components"
import { jsx } from "@trueskin-web/theme"

const ContentCta = ({
  title,
  content,
  image,
  primaryCta,
  secondaryCta,
  imageOnTheRight,
}) => (
  <div sx={{ variant: "layout.sectionMargin" }}>
    <Container
      sx={{
        display: "grid",
        gridTemplateColumns: ["auto", "repeat(2, 1fr)"],
        alignItems: "flex-start",
        gap: 7,
      }}
    >
      {image && (
        <div
          sx={{
            display: "block",
            order: [null, imageOnTheRight ? 1 : null],
          }}
        >
          <GatsbyImage
            image={image.imageFile.childImageSharp.gatsbyImageData}
            alt=""
          />
        </div>
      )}
      <div>
        <Heading size="lg" mb={[4, 5]}>
          {title}
        </Heading>

        <UserGeneratedHtml html={content} sx={{ mb: 7 }} />

        <div
          sx={{
            display: "flex",
            flexDirection: ["column", "row"],
            "& > *:first-of-type:not(:last-of-type)": {
              mb: [4, 0],
              ml: [null, 5],
              order: [null, 1],
            },
          }}
        >
          <Button
            onClick={() => {
              Object.assign(document.createElement("a"), {
                href: primaryCta.targetUrl,
              }).click()
            }}
          >
            {primaryCta.label}
          </Button>

          {secondaryCta && (
            <Button
              variant="outline"
              as={Link}
              href={secondaryCta.targetUrl}
              sx={{
                ml: [null, 5],
                mt: [4, 0],
              }}
            >
              {secondaryCta.label}
            </Button>
          )}
        </div>
      </div>
    </Container>
  </div>
)

export default ContentCta

export const contentCtaFragment = graphql`
  fragment ContentCtaFragment on Strapi_ComponentBlocksContentCta {
    title
    content
    image {
      url
      imageFile {
        childImageSharp {
          gatsbyImageData(width: 700, layout: CONSTRAINED)
        }
      }
    }
    primaryCta {
      label
      targetUrl
    }
    secondaryCta {
      label
      targetUrl
    }
    imageOnTheRight
  }
`
