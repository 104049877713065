/** @jsx jsx */
import { graphql } from "gatsby"

import { Container, Heading } from "@trueskin-web/components"
import { jsx } from "@trueskin-web/theme"

const TitleBlock = ({ blockTitle: title, position }) => {
  return (
    <div>
      <Container>
        <Heading
          as="h2"
          sx={{
            fontSize: [5, 6],
            textAlign: position || "left",
          }}
        >
          {title}
        </Heading>
      </Container>
    </div>
  )
}

export default TitleBlock

export const titleFragment = graphql`
  fragment TitleFragment on Strapi_ComponentBlocksTitle {
    blockTitle: title
    position
  }
`
