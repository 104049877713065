/** @jsx jsx */
import { Link as GatsbyLink, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import {
  Button,
  CapsBar,
  Carousel,
  Container,
  Heading,
  Rating,
  TextLink,
  UserGeneratedHtml,
} from "@trueskin-web/components"
import { jsx } from "@trueskin-web/theme"
import { Trans } from "@trueskin-web/translations"

const Reviews = ({
  title,
  reviews,
  secondaryCta,
  onHomepage,
  visibleOn = "both",
}) => {
  return (
    <div
      sx={{
        variant: "layout.sectionPadding",
        bg: "grey",
        display: [
          visibleOn === "mobile" || visibleOn === "both" ? "block" : "none",
          visibleOn === "desktop" || visibleOn === "both" ? "block" : "none",
        ],
      }}
    >
      <Container>
        <Heading size="lg" mb={[5, 6]}>
          {title}
        </Heading>

        <div
          sx={{
            display: "grid",
            gridTemplateColumns: ["auto", "repeat(3, 1fr)"],
            gap: [6, 5],
            mb: 7,
          }}
        >
          {reviews.map((review) => (
            <div key={review.author}>
              <div sx={{ mb: 5 }}>
                <div sx={{ mb: [5, 6] }}>
                  <Carousel>
                    {review.images.map((image) => (
                      <GatsbyImage
                        image={image.imageFile.childImageSharp.gatsbyImageData}
                        alt=""
                        key={image.url}
                        sx={{
                          maxHeight: [400, "initial"],
                        }}
                      />
                    ))}
                  </Carousel>
                </div>

                <Heading as="h3" mb={[1, 2]} sx={{ fontSize: [4, 5] }}>
                  {review.author}
                </Heading>

                {review.richSummary ? (
                  <UserGeneratedHtml
                    html={review.richSummary}
                    sx={{ my: 0, fontSize: [1, 2] }}
                  />
                ) : (
                  <p sx={{ my: 0, fontSize: [1, 2] }}>{review.summary}</p>
                )}
              </div>

              <CapsBar>
                <div
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Trans i18nKey="Reviews.rating" />{" "}
                  <Rating rating={review.rating} size="sm" />
                </div>
              </CapsBar>
            </div>
          ))}
        </div>

        <TextLink
          href={secondaryCta.targetUrl}
          hasArrowRight
          sx={{ display: [null, "none"] }}
        >
          {secondaryCta.label}
        </TextLink>

        <div sx={{ display: ["none", "flex"], justifyContent: "flex-end" }}>
          <Button
            as={GatsbyLink}
            to={secondaryCta.targetUrl}
            variant="outline"
            {...(onHomepage && {
              id: "reviews-cta",
            })}
          >
            {secondaryCta.label}
          </Button>
        </div>
      </Container>
    </div>
  )
}

export default Reviews

export const reviewsFragment = graphql`
  fragment ReviewsFragment on Strapi_ComponentBlocksReviews {
    title
    reviews {
      author
      images {
        url
        imageFile {
          childImageSharp {
            gatsbyImageData(width: 400, aspectRatio: 1.4, layout: CONSTRAINED)
          }
        }
      }
      summary
      richSummary
      rating
    }
    secondaryCta {
      label
      targetUrl
    }
    visibleOn
  }
`
