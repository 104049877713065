/** @jsx jsx */
import { Fragment } from "react"
import { jsx } from "theme-ui"

export const playIcon  = {
  path: (
    <Fragment>
      <circle
        cx="8.5127"
        cy="8.66846"
        r="7.5"
        stroke="currentColor"
        fill="none"
      />
      <path
        d="M12.4102 8.23547C12.7435 8.42793 12.7435 8.90905 12.4102 9.1015L6.93892 12.2603C6.60558 12.4528 6.18892 12.2122 6.18892 11.8273L6.18892 5.50967C6.18892 5.12477 6.60559 4.8842 6.93892 5.07665L12.4102 8.23547Z"
        stroke="currentColor"
        fill="none"
      />
    </Fragment>
  ),
  viewBox: "0 0 17 17",
}