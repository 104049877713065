/** @jsx jsx */
import { jsx } from "theme-ui"

export const plusIcon  = {
  path: (
    <path
      d="M12 3v18M3 12h18"
      stroke="currentColor"
      strokeWidth="1.2"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
  ),
}