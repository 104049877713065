/** @jsx jsx */
import { StaticImage } from "gatsby-plugin-image"

import { FaqContainer as FaqSection } from "@trueskin-web/components"
import { jsx } from "@trueskin-web/theme"

const image =
  process.env.GATSBY_I18N_LOCALE === "pt-BR" ? (
    <StaticImage
      src="../images/bad-treatment-good.png"
      alt="during treatment"
    />
  ) : (
    <StaticImage
      src="../images/treatment-products.png"
      alt="treatment products"
    />
  )

const FaqContainer = ({ children }) => (
  <FaqSection image={image}>{children}</FaqSection>
)

export default FaqContainer
