/** @jsx jsx */
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Fragment } from "react"

import { Container, Heading } from "@trueskin-web/components"
import { jsx } from "@trueskin-web/theme"
import { Trans, useTranslation } from "@trueskin-web/translations"

const DoctorMember = ({
  name,
  specialty,
  quote,
  studies,
  certification,
  abroad,
  priorities,
  image,
  slug,
}) => {
  const { t } = useTranslation()

  return (
    <div
      sx={{
        p: 4,
        boxShadow: "2px 2px 20px rgba(0, 0, 0, 0.08)",
      }}
    >
      {image && (
        <GatsbyImage
          image={image.imageFile.childImageSharp.gatsbyImageData}
          alt=""
          sx={{
            mb: 4,
          }}
        />
      )}
      <Heading
        as="h3"
        sx={{
          mx: 4,
          fontFamily: "monospace",
          fontSize: 2,
          textTransform: "uppercase",
        }}
      >
        {name}
      </Heading>

      {specialty && (
        <div
          sx={{
            mx: 4,
            fontFamily: "monospace",
            fontSize: 0,
          }}
        >
          {specialty}
        </div>
      )}

      {quote && (
        <div
          sx={{
            mt: 4,
            mx: 4,
            whiteSpace: "pre-wrap",
          }}
        >
          {quote}
        </div>
      )}

      <input
        type="checkbox"
        id={`doctor-member-control-${slug}`}
        hidden
        sx={{
          ":checked": {
            "~ label > span": {
              ":first-of-type": {
                display: "none",
              },
              ":not(:first-of-type)": {
                display: "inline",
              },
            },
            "~ dl": {
              display: "block",
            },
          },
        }}
      />

      <dl
        sx={{
          mx: 4,
          display: "none",
          "dt:not(:first-of-type)": {
            borderTopWidth: 1,
            borderTopStyle: "solid",
            borderColor: "#005E5D",
            mt: 2,
            pt: 2,
          },
        }}
      >
        {(studies || certification) && (
          <Fragment>
            <dt
              sx={{
                fontFamily: "monospace",
                fontSize: 0,
              }}
            >
              {studies && t("DoctorMember.studies")}{" "}
              {studies && certification && t("Generic.conjunctions,and")}{" "}
              {certification && t("DoctorMember.phd")}
            </dt>

            {studies && (
              <dd
                sx={{
                  ml: 0,
                  letterSpacing: "0.03em",
                }}
              >
                {studies}
              </dd>
            )}

            {certification && (
              <dd
                sx={{
                  ml: 0,
                  letterSpacing: "0.03em",
                }}
              >
                {certification}
              </dd>
            )}
          </Fragment>
        )}

        {abroad && (
          <Fragment>
            <dt
              sx={{
                fontFamily: "monospace",
                fontSize: 0,
              }}
            >
              <Trans i18nKey="DoctorMember.abroad" />
            </dt>
            <dd
              sx={{
                ml: 0,
                letterSpacing: "0.03em",
              }}
            >
              {abroad}
            </dd>
          </Fragment>
        )}

        {priorities && (
          <Fragment>
            <dt
              sx={{
                fontFamily: "monospace",
                fontSize: 0,
              }}
            >
              <Trans i18nKey="DoctorMember.focus" />
            </dt>
            <dd
              sx={{
                ml: 0,
                letterSpacing: "0.03em",
              }}
            >
              {priorities}
            </dd>
          </Fragment>
        )}
      </dl>

      <label
        htmlFor={`doctor-member-control-${slug}`}
        sx={{
          mt: 2,
          mx: 4,
          display: "inline-block",
          cursor: "pointer",
          textDecoration: "underline",
          letterSpacing: "0.03em",
        }}
      >
        <span>
          <Trans i18nKey="DoctorMember.readMore" />
        </span>
        <span
          sx={{
            display: "none",
          }}
        >
          <Trans i18nKey="DoctorMember.readLess" />
        </span>
      </label>
    </div>
  )
}

const DoctorMembers = ({ membersTitle: title, doctors }) => (
  <div>
    <Container>
      {title && (
        <Heading
          sx={{
            textAlign: "center",
            fontSize: [32, 38],
            letterSpacing: "0.03em",
          }}
        >
          {title}
        </Heading>
      )}
      <div
        sx={{
          display: "grid",
          gridTemplateColumns: ["auto", "repeat(3, 1fr)"],
          gridGap: [4, 5],
          my: [5, 6],
        }}
      >
        {doctors.map((doctor, index) => (
          <div key={index}>
            <DoctorMember {...doctor} index={index} />
          </div>
        ))}
      </div>
    </Container>
  </div>
)

export default DoctorMembers

export const doctorMembersFragment = graphql`
  fragment DoctorMembersFragment on Strapi_ComponentBlocksDoctorMembers {
    membersTitle: title
    doctors {
      slug
      name
      specialty
      quote
      studies
      certification
      abroad
      priorities
      image {
        url
        imageFile {
          childImageSharp {
            gatsbyImageData(width: 350, aspectRatio: 1, layout: CONSTRAINED)
          }
        }
      }
    }
  }
`
