/** @jsx jsx */
import { jsx } from "@trueskin-web/theme"
import { useTranslation } from "@trueskin-web/translations"

import checkmarkIcon from "../../images/checkmark.svg"

const VerticalStep = ({ index, step }) => {
  const { t } = useTranslation()
  return (
    <div sx={{ display: "flex" }}>
      <img
        alt=""
        src={step.icon}
        sx={{ width: 60, height: 60, mt: 8, mr: 4 }}
      />

      <div>
        <span
          sx={{
            variant: "text.caps",
            fontSize: 12,
            color: "white",
            px: "10px",
            py: "6px",
            backgroundColor: "#005858",
          }}
        >
          {t("HowItWorks.step", { number: index })}
        </span>

        <dl
          sx={{
            my: 2,
            borderLeft: "2px solid ",
            borderColor: "#005858",
            pl: 4,
            py: 6,
          }}
        >
          <dt sx={{ fontSize: 18, mb: 4 }}>
            <span>{step.title}</span>
          </dt>

          {step.lines.map((line, index) => (
            <dd
              key={index}
              sx={{
                display: "flex",
                alignItems: "flex-start",
                marginInlineStart: "unset",
                "&:not(:last-of-type)": {
                  mb: 4,
                },
              }}
            >
              <img alt="" sx={{ mr: 16, mt: 1 }} src={checkmarkIcon} />
              <span>{line}</span>
            </dd>
          ))}
        </dl>
      </div>
    </div>
  )
}

export default VerticalStep
