/** @jsx jsx */
import { useLocation } from "@reach/router"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Fragment, useEffect, useState } from "react"
import { useInView } from "react-intersection-observer"
import "url-search-params-polyfill"

import { paymentClient, useMutation } from "@trueskin-web/apis"
import {
  Button,
  Container,
  ContentRating,
  MobileCta,
  MobileStickyCta,
} from "@trueskin-web/components"
import { storageService, voucherService } from "@trueskin-web/services"
import { jsx } from "@trueskin-web/theme"

import sealImage from "../images/seal.svg"

import ContentPrice from "./components/content-price"
import ContentSection from "./components/content-section"

const storageKeys = storageService.getStorageKeys()
const voucherParam = storageKeys.VOUCHER_URL
const headlineParam = "hd"
const subheadlineParam = "sub_hd"

const LpHeroMobileCta = ({ cta, ctaDescription, isCtaSticky }) => {
  const [inViewRef, isInView] = useInView({
    threshold: 0,
  })

  return (
    <Fragment>
      <MobileCta
        ref={inViewRef}
        title={cta.label}
        description={ctaDescription}
        targetUrl={cta.targetUrl}
      />
      {isCtaSticky && (
        <MobileStickyCta
          show={!isInView}
          title={cta.label}
          description={ctaDescription}
          targetUrl={cta.targetUrl}
        />
      )}
    </Fragment>
  )
}

const LpHeroInfo = ({
  title,
  description,
  rating,
  ratingDescription,
  trustElement,
  cta,
  ctaDescription,
  isCtaSticky,
}) => {
  const [inViewRef, inView] = useInView({
    threshold: 0,
  })

  if (!title && !description && !cta) {
    return null
  }

  return (
    <div
      sx={{
        display: "flex",
        flex: 1,
        alignItems: ["initial", "center"],
      }}
    >
      <div
        sx={{
          maxWidth: ["75%", "50%"],
          pt: [6, 0],
        }}
      >
        <ContentSection title={title} description={description} />

        <div
          sx={{
            width: ["auto", "60%"],
            display: "flex",
            alignItems: "flex-start",
            flexDirection: ["column", "row"],
            gap: [0, 6],
            mt: 6,
            mb: 5,
          }}
        >
          <ContentRating rating={rating} description={ratingDescription} />

          {trustElement && (
            <GatsbyImage
              image={trustElement.imageFile.childImageSharp.gatsbyImageData}
              alt={trustElement.alternativeText}
              sx={{ maxWidth: 82, mt: [2, "-5px"] }}
            />
          )}
        </div>

        {cta && (
          <Fragment>
            <div
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "fit-content",
              }}
            >
              <Button
                size="lg"
                ref={inViewRef}
                onClick={() => {
                  Object.assign(document.createElement("a"), {
                    href: cta.targetUrl,
                  }).click()
                }}
                sx={{
                  mt: 4,
                  px: [4, 8],
                  minWidth: 370,
                }}
              >
                {cta.label}
              </Button>

              {isCtaSticky && (
                <MobileStickyCta
                  show={!inView}
                  title={cta.label}
                  description={ctaDescription}
                  targetUrl={cta.targetUrl}
                />
              )}

              {ctaDescription && (
                <div
                  sx={{
                    mt: 2,
                    fontSize: 1,
                    textAlign: "center",
                  }}
                >
                  {ctaDescription}
                </div>
              )}
            </div>
          </Fragment>
        )}
      </div>
    </div>
  )
}

const LpHeroPrice = ({ price, priceCurrency, color, backgroundColor }) => (
  <ContentPrice
    price={price}
    priceCurrency={priceCurrency}
    color={color}
    backgroundColor={backgroundColor}
    csx={{
      position: "absolute",
      bottom: [6, 8],
      right: [5, 8],
    }}
  />
)

const LpHeroImageDescription = ({ description }) => (
  <div
    sx={{
      maxWidth: ["auto", "50%"],
      mx: ["2%", "auto"],
      pt: "2%",
      fontSize: 0,
      fontStyle: "italic",
    }}
  >
    {description}
  </div>
)

const LpHero = ({
  image,
  smallImage,
  imageDescription,
  trustElement,
  heroTitle,
  heroDescription,
  cta,
  ctaDescription,
  isCtaSticky,
  rating,
  ratingDescription,
  price,
  lpPriceCurrency,
  priceColor,
  priceBackgroundColor,
  backgroundColor,
}) => (
  <Fragment>
    <div
      sx={{
        display: ["block", "none"],
        backgroundColor,
      }}
    >
      <div
        sx={{
          position: "relative",
        }}
      >
        <GatsbyImage
          image={smallImage.imageFile.childImageSharp.gatsbyImageData}
          alt={smallImage.alternativeText}
          sx={{ maxHeight: 450 }}
        />
        <div
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          }}
        >
          <Container
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-evenly",
              height: "100%",
              py: "2%",
            }}
          >
            <LpHeroInfo
              title={heroTitle}
              description={heroDescription}
              rating={rating}
              ratingDescription={ratingDescription}
              trustElement={trustElement}
            />

            {imageDescription && (
              <LpHeroImageDescription description={imageDescription} />
            )}

            <LpHeroPrice
              price={price}
              priceCurrency={lpPriceCurrency}
              color={priceColor}
              backgroundColor={priceBackgroundColor}
            />
          </Container>
        </div>
        <img
          data-component-name="MoneyBackBadge"
          src={sealImage}
          alt=""
          sx={{
            visibility: "hidden",
            position: "absolute",
            bottom: 6,
            left: 4,
          }}
        />
      </div>

      <LpHeroMobileCta
        cta={cta}
        ctaDescription={ctaDescription}
        isCtaSticky={isCtaSticky}
      />
    </div>
    <div
      sx={{
        display: ["none", "block"],
        backgroundColor,
      }}
    >
      <Container>
        <div
          sx={{
            position: "relative",
          }}
        >
          <div sx={{ width: "100%", textAlign: "right" }}>
            <GatsbyImage
              image={image.imageFile.childImageSharp.gatsbyImageData}
              sx={{ maxHeight: 686 }}
              alt={image.alternativeText}
            />
          </div>
          <div
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
            }}
          >
            <div
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-evenly",
                height: "100%",
                py: "2%",
                px: "8%",
              }}
            >
              <LpHeroInfo
                title={heroTitle}
                description={heroDescription}
                rating={rating}
                ratingDescription={ratingDescription}
                trustElement={trustElement}
                cta={cta}
                ctaDescription={ctaDescription}
              />

              {imageDescription && (
                <LpHeroImageDescription description={imageDescription} />
              )}

              <LpHeroPrice
                price={price}
                priceCurrency={lpPriceCurrency}
                color={priceColor}
                backgroundColor={priceBackgroundColor}
              />
            </div>
          </div>
        </div>
      </Container>
    </div>
  </Fragment>
)

const LpHeroFragment = ({
  blockIsFirst,
  heroTitle,
  heroDescription,
  ctaDescription,
  ...props
}) => {
  const location = useLocation()
  const [title, setTitle] = useState(heroTitle)
  const [description, setDescription] = useState(heroDescription)
  const [ctaDetails, setCtaDetails] = useState(ctaDescription)

  const { mutateAsync: checkVoucher } = useMutation(paymentClient.infoVoucher, {
    onSuccess: ({ message }) => {
      setCtaDetails(message)
    },
  })

  useEffect(() => {
    if (!blockIsFirst) {
      return
    }

    const searchParams = new URLSearchParams(location.search)
    const headline = searchParams.get(headlineParam)
    const subheadline = searchParams.get(subheadlineParam)
    const voucher =
      searchParams.get(voucherParam) || voucherService.getVoucher()

    if (headline) {
      setTitle(headline)
    }

    if (subheadline) {
      setDescription(subheadline)
    }

    if (voucher) {
      checkVoucher(voucher)
    }
  }, [blockIsFirst, location.search, checkVoucher])

  return (
    <LpHero
      heroTitle={title}
      heroDescription={description}
      ctaDescription={ctaDetails}
      {...props}
    />
  )
}

export default LpHeroFragment

export const lpHeroFragment = graphql`
  fragment LpHeroFragment on Strapi_ComponentBlocksLpHero {
    image {
      url
      alternativeText
      imageFile {
        childImageSharp {
          gatsbyImageData(height: 686, layout: CONSTRAINED)
        }
      }
    }
    smallImage {
      url
      alternativeText
      imageFile {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
    imageDescription
    heroTitle: title
    heroDescription: description
    cta {
      label
      targetUrl
    }
    ctaDescription
    isCtaSticky: stickyCta
    rating
    ratingDescription
    price
    lpPriceCurrency: priceCurrency
    priceColor
    priceBackgroundColor
    backgroundColor
    trustElement {
      url
      alternativeText
      imageFile {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
  }
`
