/** @jsx jsx */
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Fragment } from "react"

import { CdnImage, Container, Heading } from "@trueskin-web/components"
import { jsx } from "@trueskin-web/theme"

import SignatureImage from "./components/signature-image"

const HowItWorksGuide = ({
  guideTitle: title,
  guideBGColor: backgroundColor,
  image,
  steps,
}) => (
  <div
    sx={{
      bg: backgroundColor,
      position: "relative",
      pt: [6, 8],
      pb: [5, 7],
    }}
  >
    <Container>
      <div
        sx={{
          width: [null, "50%"],
        }}
      >
        <Heading
          sx={{
            mb: [3, 4],
            textAlign: "center",
            fontSize: [32, 38],
            letterSpacing: "0.03em",
          }}
        >
          {title}
        </Heading>
        <div
          sx={{
            counterReset: "count",
            position: "relative",
            display: "grid",
            gridTemplateColumns: "auto 1fr",
            gridGap: [4, 5],
            ":before": {
              content: `""`,
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 29,
              width: 1,
              boxShadow: "1px 0 0 0",
            },
          }}
        >
          {steps.map(({ stepTitle, icon }, index) => (
            <Fragment key={index}>
              <div
                sx={{
                  counterIncrement: "count",
                  position: "relative",
                  display: "inline-flex",
                  alignItems: "center",
                  justifyContent: "center",
                  border: "1px",
                  borderRadius: "50%",
                  width: 60,
                  height: 60,
                  p: 3,
                  bg: backgroundColor,
                  ":not(:first-of-type):before, :not(:last-of-type):after": {
                    content: `""`,
                    position: "absolute",
                    p: "2px",
                    bg: backgroundColor,
                  },
                  ":before": {
                    top: "-5px",
                  },
                  ":after": {
                    bottom: "-5px",
                  },
                }}
              >
                <div
                  sx={{
                    ":before": {
                      content: "counter(count)",
                      position: "absolute",
                      top: 0,
                      left: 0,
                      bg: backgroundColor,
                      p: "8px 4px",
                      lineHeight: 0,
                      fontSize: 1,
                    },
                  }}
                />
                {icon.imageFile.childImageSharp ? (
                  <GatsbyImage
                    image={icon.imageFile.childImageSharp.gatsbyImageData}
                    alt=""
                    sx={{
                      maxWidth: "100%",
                      maxHeight: "100%",
                      flex: 1,
                      zIndex: 2,
                    }}
                  />
                ) : (
                  <CdnImage
                    src={icon.url}
                    sx={{
                      maxWidth: "100%",
                      maxHeight: "100%",
                      flex: 1,
                      zIndex: 2,
                    }}
                  />
                )}
              </div>
              <Heading
                as="h3"
                sx={{
                  alignSelf: "center",
                  fontSize: [2, 3],
                  letterSpacing: "0.03em",
                }}
              >
                {stepTitle}
              </Heading>
            </Fragment>
          ))}
        </div>
      </div>
    </Container>
    <div
      sx={{
        display: ["none", "block"],
        position: "absolute",
        top: 0,
        left: "50%",
        right: 0,
        bottom: 0,
      }}
    >
      <GatsbyImage
        image={image.imageFile.childImageSharp.gatsbyImageData}
        alt=""
        sx={{
          width: "100%",
          height: "100%",
        }}
      />
    </div>
    <SignatureImage
      q2={{ width: 3, height: 4 }}
      q4={{ width: 3, height: 4 }}
      sx={{
        position: "absolute",
        top: [0, "50%"],
        left: [null, "50%"],
        right: 0,
        transform: [null, "translate(-50%, -50%)"],
        fontSize: [1, 2],
      }}
    />
  </div>
)

export default HowItWorksGuide

export const conditionsFragment = graphql`
  fragment HowItWorksGuideFragment on Strapi_ComponentBlocksHowItWorksGuide {
    guideTitle: title
    guideBGColor: backgroundColor
    image {
      url
      imageFile {
        childImageSharp {
          gatsbyImageData(width: 800, layout: CONSTRAINED)
        }
      }
    }
    steps {
      stepTitle: title
      icon {
        url
        imageFile {
          childImageSharp {
            gatsbyImageData(width: 54, height: 54, layout: FIXED)
          }
        }
      }
    }
  }
`
