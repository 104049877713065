/** @jsx jsx */
import { useLocation } from "@reach/router"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Fragment, useEffect, useState } from "react"
import { useInView } from "react-intersection-observer"
import "url-search-params-polyfill"

import { paymentClient, useMutation } from "@trueskin-web/apis"
import {
  Button,
  Container,
  ContentRating,
  MobileCta,
  MobileStickyCta,
} from "@trueskin-web/components"
import { storageService, voucherService } from "@trueskin-web/services"
import { jsx } from "@trueskin-web/theme"

import ContentPrStrip from "./components/content-pr-strip"
import ContentPrice from "./components/content-price"
import ContentSection from "./components/content-section"

const storageKeys = storageService.getStorageKeys()
const voucherParam = storageKeys.VOUCHER_URL
const headlineParam = "hd"
const subheadlineParam = "sub_hd"

const FullSizeHeroMobileCta = ({ cta, ctaDescription, isCtaSticky }) => {
  const [inViewRef, isInView] = useInView({
    threshold: 0,
  })

  return (
    <Fragment>
      <MobileCta
        ref={inViewRef}
        title={cta.label}
        description={ctaDescription}
        targetUrl={cta.targetUrl}
      />
      {isCtaSticky && (
        <MobileStickyCta
          show={!isInView}
          title={cta.label}
          description={ctaDescription}
          targetUrl={cta.targetUrl}
        />
      )}
    </Fragment>
  )
}

const FullSizeHeroInfo = ({
  title,
  description,
  cta,
  ctaDescription,
  isCtaSticky,
  isCtaInline,
}) => {
  const [inViewRef, inView] = useInView({
    threshold: 0,
  })

  if (!title && !description && !cta) {
    return null
  }

  return (
    <div
      sx={{
        display: "flex",
        flex: 1,
        alignItems: "center",
      }}
    >
      <div
        sx={{
          maxWidth: [isCtaInline ? "100%" : "60%", "50%"],
        }}
      >
        <ContentSection title={title} description={description} />

        {cta && (
          <Fragment>
            <div
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "fit-content",
              }}
            >
              <Button
                ref={inViewRef}
                onClick={() => {
                  Object.assign(document.createElement("a"), {
                    href: cta.targetUrl,
                  }).click()
                }}
                sx={{
                  mt: 4,
                  px: [4, 8],
                  minWidth: 370,
                }}
              >
                {cta.label}
              </Button>

              {isCtaSticky && (
                <MobileStickyCta
                  show={!inView}
                  title={cta.label}
                  description={ctaDescription}
                  targetUrl={cta.targetUrl}
                />
              )}

              {ctaDescription && (
                <div
                  sx={{
                    mt: 2,
                    fontSize: 1,
                    textAlign: "center",
                  }}
                >
                  {ctaDescription}
                </div>
              )}
            </div>
          </Fragment>
        )}
      </div>
    </div>
  )
}

const FullSizeHeroPrice = ({
  price,
  priceCurrency,
  color,
  backgroundColor,
}) => (
  <ContentPrice
    price={price}
    priceCurrency={priceCurrency}
    color={color}
    backgroundColor={backgroundColor}
    csx={{
      position: "absolute",
      bottom: [6, 8],
      right: [5, 8],
    }}
  />
)

const FullSizeHero = ({
  image,
  smallImage,
  heroTitle,
  heroDescription,
  cta,
  ctaDescription,
  isCtaSticky,
  isCtaInline,
  prTitle,
  prItems,
  rating,
  ratingDescription,
  price,
  priceCurrency,
  priceColor,
  priceBackgroundColor,
  backgroundColor,
}) => (
  <Fragment>
    <div
      sx={{
        display: ["block", "none"],
        backgroundColor,
      }}
    >
      <div
        sx={{
          position: "relative",
        }}
      >
        <GatsbyImage
          image={smallImage.imageFile.childImageSharp.gatsbyImageData}
          alt=""
        />
        <div
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          }}
        >
          <Container
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-evenly",
              height: "100%",
              py: "2%",
            }}
          >
            {isCtaInline ? (
              <FullSizeHeroInfo
                title={heroTitle}
                description={heroDescription}
                cta={cta}
                ctaDescription={ctaDescription}
                isCtaSticky={isCtaSticky}
                isCtaInline={isCtaInline}
              />
            ) : (
              <FullSizeHeroInfo
                title={heroTitle}
                description={heroDescription}
              />
            )}
            <ContentPrStrip
              title={prTitle}
              items={prItems}
              sx={{
                pt: "2%",
              }}
            />
            <ContentRating
              rating={rating}
              description={ratingDescription}
              sx={{
                pt: "2%",
              }}
            />
            <FullSizeHeroPrice
              price={price}
              priceCurrency={priceCurrency}
              color={priceColor}
              backgroundColor={priceBackgroundColor}
            />
          </Container>
        </div>
      </div>
      {!isCtaInline && (
        <FullSizeHeroMobileCta
          cta={cta}
          ctaDescription={ctaDescription}
          isCtaSticky={isCtaSticky}
        />
      )}
    </div>
    <div
      sx={{
        display: ["none", "block"],
        backgroundColor,
      }}
    >
      <Container>
        <div
          sx={{
            position: "relative",
          }}
        >
          <GatsbyImage
            image={image.imageFile.childImageSharp.gatsbyImageData}
            alt=""
          />
          <div
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
            }}
          >
            <div
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-evenly",
                height: "100%",
                py: "2%",
                px: 9,
              }}
            >
              <FullSizeHeroInfo
                title={heroTitle}
                description={heroDescription}
                cta={cta}
                ctaDescription={ctaDescription}
              />
              <ContentPrStrip
                title={prTitle}
                items={prItems}
                sx={{
                  pt: "2%",
                }}
              />
              <ContentRating
                rating={rating}
                description={ratingDescription}
                sx={{
                  pt: "2%",
                }}
              />
              <FullSizeHeroPrice
                price={price}
                priceCurrency={priceCurrency}
                color={priceColor}
                backgroundColor={priceBackgroundColor}
              />
            </div>
          </div>
        </div>
      </Container>
    </div>
  </Fragment>
)

const FullSizeHeroFragment = ({
  blockIsFirst,
  heroTitle,
  heroDescription,
  ctaDescription,
  ...props
}) => {
  const location = useLocation()
  const [title, setTitle] = useState(heroTitle)
  const [description, setDescription] = useState(heroDescription)
  const [ctaDetails, setCtaDetails] = useState(ctaDescription)

  const { mutateAsync: checkVoucher } = useMutation(paymentClient.infoVoucher, {
    onSuccess: ({ message }) => {
      setCtaDetails(message)
    },
  })

  useEffect(() => {
    if (!blockIsFirst) {
      return
    }

    const searchParams = new URLSearchParams(location.search)
    const headline = searchParams.get(headlineParam)
    const subheadline = searchParams.get(subheadlineParam)
    const voucher =
      searchParams.get(voucherParam) || voucherService.getVoucher()

    if (headline) {
      setTitle(headline)
    }

    if (subheadline) {
      setDescription(subheadline)
    }

    if (voucher) {
      checkVoucher(voucher)
    }
  }, [blockIsFirst, location.search, checkVoucher])

  return (
    <FullSizeHero
      heroTitle={title}
      heroDescription={description}
      ctaDescription={ctaDetails}
      {...props}
    />
  )
}

export default FullSizeHeroFragment

export const fullSizeHeroFragment = graphql`
  fragment FullSizeHeroFragment on Strapi_ComponentBlocksFullSizeHero {
    image {
      url
      imageFile {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
    smallImage {
      url
      imageFile {
        childImageSharp {
          gatsbyImageData(width: 800, layout: CONSTRAINED)
        }
      }
    }
    heroTitle: title
    heroDescription: description
    cta {
      label
      targetUrl
    }
    ctaDescription
    isCtaSticky: stickyCta
    isCtaInline: inlineCta
    prTitle
    prItems {
      alternateText
      image {
        url
        imageFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
    rating
    ratingDescription
    price
    priceCurrency
    priceColor
    priceBackgroundColor
    backgroundColor
  }
`
