/** @jsx jsx */
import { graphql } from "gatsby"

import { Container, Heading, Icon, Rating } from "@trueskin-web/components"
import { jsx } from "@trueskin-web/theme"
import { checkCircleIcon } from "@trueskin-web/theme/icons/checkCircleIcon"

const BannerItem = ({ title, description, ...props }) => (
  <div
    sx={{
      display: "inline-flex",
      alignItems: "center",
    }}
    {...props}
  >
    <Icon
      icon={checkCircleIcon}
      size="xsm"
      sx={{
        display: ["inline-block", "none"],
        mr: 1,
      }}
    />
    <Icon
      icon={checkCircleIcon}
      size="sm"
      sx={{
        display: ["none", "inline-block"],
        mr: 2,
      }}
    />
    <div
      sx={{
        display: "inline-flex",
        flexDirection: "column",
      }}
    >
      <Heading
        as="h6"
        sx={{
          fontSize: ["8px", "14px"],
          fontWeight: "bold",
        }}
      >
        {title}
      </Heading>
      <span
        sx={{
          fontSize: ["8px", "14px"],
          lineHeight: 1,
        }}
      >
        {description}
      </span>
    </div>
  </div>
)

const BannerDetails = ({ items }) => (
  <div
    sx={{
      display: "flex",
      // alignItems: "center",
      justifyContent: "space-between",
    }}
  >
    {items.map(({ title, description }, index) => (
      <BannerItem
        key={index}
        title={title}
        description={description}
        sx={{
          ml: index !== 0 ? [0, 6] : undefined,
        }}
      ></BannerItem>
    ))}
  </div>
)

const BannerRating = ({ rating }) => (
  <div
    sx={{
      display: "inline-flex",
      flexDirection: ["row", "column"],
      justifyContent: "center",
      alignItems: "baseline",
      mt: [2, 0],
    }}
  >
    <div
      sx={{
        display: "inline-flex",
        alignItems: "center",
      }}
    >
      <span
        sx={{
          fontSize: ["10px", "18px"],
        }}
      >
        {rating.score}
      </span>
      <Rating
        rating={rating.score}
        size="sm"
        sx={{
          display: ["block", "none"],
          color: "goldDark",
          ml: 2,
        }}
      />
      <Rating
        rating={rating.score}
        size="md"
        sx={{
          display: ["none", "block"],
          color: "goldDark",
          ml: 2,
        }}
      />
    </div>
    <div
      sx={{
        fontSize: ["8px", "12px"],
      }}
    >
      {rating.description}
    </div>
  </div>
)

const BannerUsp = ({
  uspItems: items,
  uspRating: rating,
  backgroundColor,
  isFullWidth,
}) => (
  <div
    sx={{
      backgroundColor: isFullWidth ? backgroundColor : undefined,
    }}
  >
    <Container>
      <div
        sx={{
          backgroundColor: isFullWidth ? undefined : backgroundColor,
          display: "flex",
          flexDirection: ["column", "row"],
          justifyContent: "space-between",
          alignItems: [null, "center"],
          px: [2, 6],
          py: [2, 2],
        }}
      >
        <BannerDetails items={items} />
        <BannerRating rating={rating} />
      </div>
    </Container>
  </div>
)

export default BannerUsp

export const bannerUspFragment = graphql`
  fragment BannerUspFragment on Strapi_ComponentBlocksBannerUsp {
    uspItems: items {
      title
      description
    }
    uspRating: rating {
      score
      description
    }
    backgroundColor
    isFullWidth
  }
`
