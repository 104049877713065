/** @jsx jsx */
import { getSrc } from "gatsby-plugin-image"
import ImageCompareViewer from "image-compare-viewer"
import { useEffect, useRef, useState } from "react"

import { CdnImage, Tippy } from "@trueskin-web/components"
import { jsx } from "@trueskin-web/theme"
import { useTranslation } from "@trueskin-web/translations"

import useImageLoad from "../../../../portal/src/app/hooks/use-image-load"

/**
 *
 * @param {ImageCompareViewerOptions} options https://image-compare-viewer.netlify.app/#anc_3
 */
const ImageCompare = ({ before, after, options, showHelper }) => {
  const { t } = useTranslation()
  const elRef = useRef(null)

  const [isHelperEnabled, setIsHelperEnabled] = useState(null)

  const [beforeImageRef, isBeforeImageLoaded] = useImageLoad()
  const [afterImageRef, isAfterImageLoaded] = useImageLoad()

  useEffect(() => {
    if (!isHelperEnabled) {
      return
    }

    const element = elRef.current

    const disableHelper = () => {
      element.removeEventListener("mousedown", disableHelper)
      element.removeEventListener("touchstart", disableHelper)

      setIsHelperEnabled(false)
    }

    element.addEventListener("mousedown", disableHelper, { passive: true })
    element.addEventListener("touchstart", disableHelper, { passive: true })

    return () => {
      element.removeEventListener("mousedown", disableHelper)
      element.removeEventListener("touchstart", disableHelper)
    }
  }, [elRef, isHelperEnabled])

  useEffect(() => {
    if (!isBeforeImageLoaded || !isAfterImageLoaded) {
      return
    }

    const element = elRef.current

    new ImageCompareViewer(element, {
      smoothing: false,
      addCircle: true,
      ...options,
    }).mount()

    setIsHelperEnabled(true)
  }, [isBeforeImageLoaded, isAfterImageLoaded, elRef, options])

  return (
    <div
      sx={{
        position: "relative",
        paddingTop: "100%",
        overflow: "hidden",
      }}
    >
      <div
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          transition: "opacity 0.3s",
          opacity: isHelperEnabled !== null ? 1 : 0,
        }}
      >
        <div ref={elRef}>
          <CdnImage
            ref={beforeImageRef}
            src={getSrc(before.imageFile)}
            alt="before"
          />
          <CdnImage
            ref={afterImageRef}
            src={getSrc(after.imageFile)}
            alt="after"
          />
        </div>

        {isHelperEnabled && showHelper && (
          <Tippy
            theme="light"
            placement="top-start"
            content={t("ImageCompare.tooltip")}
            showOnCreate
            hideOnClick={false}
          >
            <div
              sx={{
                width: 50,
                height: 60,
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            />
          </Tippy>
        )}
      </div>
    </div>
  )
}

export default ImageCompare
