/** @jsx jsx */
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import {
  Fragment,
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react"

import { Button, Container } from "@trueskin-web/components"
import { conditionalFunction } from "@trueskin-web/functions"
import { jsx, useBreakpointIndex } from "@trueskin-web/theme"

const Condition = forwardRef(({ image, cta, ...props }, ref) => (
  <div
    ref={ref}
    sx={{
      position: "relative",
      p: [null, 6],
      my: [null, 6],
      mx: [null, 3],
      ":before, :after": {
        content: [null, `""`],
        position: "absolute",
        zIndex: -1,
        width: 80,
        height: 30,
      },
      ":before": {
        top: "-1px",
        right: "-1px",
        boxShadow: "1px -1px 0px 0px",
      },
      ":after": {
        bottom: "-1px",
        left: "-1px",
        boxShadow: "-1px 1px 0px 0px",
      },
    }}
    {...props}
  >
    <div
      sx={{
        position: "relative",
      }}
    >
      <GatsbyImage
        image={image.imageFile.childImageSharp.gatsbyImageData}
        alt=""
      />
      {cta && (
        <div
          sx={{
            position: "absolute",
            left: 0,
            right: 0,
            bottom: [5, 5],
            mx: [4, 6],
          }}
        >
          <Button
            onClick={() => {
              Object.assign(document.createElement("a"), {
                href: cta.targetUrl,
              }).click()
            }}
            variant="outline"
            color="white"
            isFullWidth
          >
            {cta.label}
          </Button>
        </div>
      )}
    </div>
  </div>
))

const ConditionList = ({ conditions }) => {
  const lastCondition = useRef(null)
  const [gridElementHeight, setGridElementHeight] = useState()
  const isMobile = useBreakpointIndex() === 0

  const handleResize = useCallback(() => {
    conditionalFunction(
      () => lastCondition.current,
      () => setGridElementHeight(lastCondition.current.offsetHeight)
    )
  }, [lastCondition])

  useEffect(() => {
    if (isMobile || conditions.length % 2 !== 0) {
      return
    }

    window.addEventListener("resize", handleResize)
    handleResize()

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [handleResize, isMobile, conditions])

  return (
    <Fragment>
      {conditions.map((props, index) => (
        <Condition
          key={index}
          ref={index === conditions.length - 1 ? lastCondition : null}
          {...props}
          sx={{
            transform: [null, index % 2 === 1 ? "translateY(50%)" : null],
          }}
        />
      ))}
      {!isMobile && conditions.length % 2 === 0 && (
        <div
          sx={{
            height: `calc(${gridElementHeight}px / 2)`,
          }}
        ></div>
      )}
    </Fragment>
  )
}

const Conditions = (props) => (
  <div>
    <Container noMobilePadding>
      <div
        sx={{
          display: [null, "grid"],
          gridTemplateColumns: [null, "repeat(2, 1fr)"],
        }}
      >
        <ConditionList {...props} />
      </div>
    </Container>
  </div>
)

export default Conditions

export const conditionsFragment = graphql`
  fragment ConditionsListFragment on Strapi_ComponentBlocksConditionsList {
    conditions {
      image {
        url
        imageFile {
          childImageSharp {
            gatsbyImageData(width: 472, aspectRatio: 1, layout: CONSTRAINED)
          }
        }
      }
      cta {
        label
        targetUrl
      }
    }
  }
`
