/** @jsx jsx */
import { graphql } from "gatsby"

import {
  Button,
  Heading,
  Select,
  UserGeneratedHtml,
} from "@trueskin-web/components"
import { jsx } from "@trueskin-web/theme"
import { useTranslation } from "@trueskin-web/translations"

import { BadgeItem } from "./badges"

const BlogPitch = ({
  title,
  backgroundColor,
  richDescription,
  primaryCta,
  badge,
}) => {
  const { t } = useTranslation()
  return (
    <div sx={{ my: 8 }}>
      <div
        sx={{
          maxWidth: "container",
          mx: "auto",
          backgroundColor,
          maxHeight: null,
          px: [null, null],
        }}
      >
        <div
          sx={{
            minHeight: [null, 544],
            px: [4, 9],
            // py: [7, 8],
            pt: [7, "124px"],
            pb: [7, 8],
          }}
        >
          <div
            sx={{
              display: [null, "flex"],
              flexDirection: [null, "column"],
              justifyContent: [null, "center"],
              maxWidth: [null, 670],
            }}
          >
            <Heading as="h3" size="xl" mb={5}>
              {title}
            </Heading>

            <UserGeneratedHtml
              html={richDescription}
              sx={{ mt: 0, mb: 5, fontSize: 3 }}
            />

            {badge && (
              <div sx={{ mb: 5 }}>
                <BadgeItem {...badge} />
              </div>
            )}
          </div>

          <div
            sx={{
              display: "flex",
              flexDirection: ["column", "row"],
              justifyContent: "flex-end",
              alignItems: "stretch",
            }}
          >
            <Select
              size="lg"
              placeholder={t("FakeSelect.label")}
              color="primary"
              sx={{ fontSize: 4, backgroundColor: "white" }}
            >
              <option value="akne">{t("FakeSelect.acne")}</option>
              <option value="pickel">{t("FakeSelect.pimple")}</option>
              <option value="unreinheiten">
                {t("FakeSelect.imperfections")}
              </option>
              <option value="flecken">{t("FakeSelect.stains")}</option>
              <option value="rosacea">{t("FakeSelect.rosacea")}</option>
              <option value="andere">{t("FakeSelect.other")}</option>
            </Select>

            <Button
              onClick={() => {
                Object.assign(document.createElement("a"), {
                  href: primaryCta.targetUrl,
                }).click()
              }}
              sx={{
                ml: [null, 5],
                mt: [4, 0],
              }}
            >
              {primaryCta.label}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BlogPitch

export const blogPitchFragment = graphql`
  fragment BlogPitchFragment on Strapi_ComponentBlocksBlogPitch {
    title
    richDescription
    backgroundColor
    primaryCta {
      label
      targetUrl
    }
    badge {
      title
      description
      image {
        url
        imageFile {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
      }
      width
    }
  }
`
