/** @jsx jsx */
import { graphql, useStaticQuery } from "gatsby"
import { getSrc } from "gatsby-plugin-image"
import { Fragment, useState } from "react"

import {
  Button,
  CdnImage,
  Container,
  Dialog,
  GoogleRating,
  Heading,
  Icon,
  TrustpilotRating,
  YoutubeEmbed,
} from "@trueskin-web/components"
import { jsx } from "@trueskin-web/theme"
import { playIcon } from "@trueskin-web/theme/icons/playIcon"

import SignatureImage from "./components/signature-image"
import Typewriter from "./components/typewriter"

const HeroTrustpilot = ({
  image,
  smallImage,
  heroTrustTitle: title,
  heroTrustDescription: description,
  cta,
  heroCTADescription: ctaDescription,
  secondaryCta,
  isSecondaryCtaYoutube,
  trustpilotDescription,
  backgroundColor,
  enableRating,
  ratingService,
  isImageFullWidth,
}) => {
  const [dialogYoutube, setDialogYoutube] = useState(false)

  // const images = useMemo(
  //   () =>
  //     withArtDirection(getImage(image.imageFile), [
  //       {
  //         media: "(max-width: 54em)",
  //         image: getImage(smallImage.imageFile),
  //       },
  //     ]),
  //   [image, smallImage]
  // )

  const {
    google: {
      result: { rating: googleRating },
    },
    trustpilot: {
      score: { trustScore: trustpilotRating },
    },
  } = useStaticQuery(
    graphql`
      {
        google {
          result {
            rating
          }
        }
        trustpilot {
          score {
            trustScore
          }
        }
      }
    `
  )

  return (
    <div
      sx={{
        position: "relative",
        overflow: "hidden",
        backgroundColor,
      }}
    >
      {/* <GatsbyImage
        image={images}
        alt=""
        objectFit="contain"
        objectPosition="0 50%"
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          bottom: 0,
          left: ["65%", "55%"],
          maxHeight: "100%",
        }}
      /> */}
      <div
        sx={{
          display: [undefined, "none"],
          position: "absolute",
          top: 0,
          right: 0,
          bottom: 0,
          left: isImageFullWidth ? 0 : "55%",
        }}
      >
        <CdnImage
          src={getSrc(smallImage.imageFile)}
          sx={{
            height: "100%",
            width: isImageFullWidth ? "100%" : undefined,
            objectFit: "cover",
          }}
        />
      </div>

      <Container>
        <div
          sx={{
            position: "relative",
            pt: [0, 9],
            zIndex: 1,
          }}
        >
          <div
            sx={{
              display: ["none", "block"],
              position: "absolute",
              top: 0,
              right: 0,
              bottom: 0,
              left: isImageFullWidth ? 0 : "55%",
              zIndex: -1,
            }}
          >
            <CdnImage
              src={getSrc(image.imageFile)}
              sx={{
                height: "100%",
                width: isImageFullWidth ? "100%" : undefined,
                objectFit: "cover",
              }}
            />
          </div>
          <div
            sx={{
              position: "relative",
            }}
          >
            <Heading
              as="h1"
              sx={{
                width: ["75%", "60%"],
                fontSize: [36, 48],
                letterSpacing: "0.03em",
              }}
            >
              <Typewriter text={title} />
            </Heading>
            <SignatureImage
              q1={{ width: 4, height: 3 }}
              q3={{ width: 2, height: 3 }}
              sx={{
                display: ["none", "grid"],
                position: "absolute",
                top: -2,
                left: -3,
                transform: "translate(-33%, -50%)",
              }}
            />
          </div>
          {enableRating && (
            <Fragment>
              {ratingService === "trustpilot" && (
                <TrustpilotRating
                  rating={trustpilotRating}
                  sx={{
                    mt: [6, 40],
                  }}
                />
              )}
              {ratingService === "google" && (
                <GoogleRating
                  rating={googleRating}
                  sx={{
                    mt: [6, 40],
                  }}
                />
              )}

              {trustpilotDescription && (
                <div
                  dangerouslySetInnerHTML={{ __html: trustpilotDescription }}
                  sx={{
                    width: "min(75%, 310px)",
                    fontSize: 0,
                    "*": {
                      p: 0,
                      m: 0,
                    },
                  }}
                />
              )}
            </Fragment>
          )}

          <div
            dangerouslySetInnerHTML={{ __html: description }}
            sx={{
              width: ["75%", "60%"],
              mt: [4, 5],
              fontSize: [2, 4],
              "p, ul, ol": {
                my: 0,
              },
              ul: {
                listStyle: "none",
                pl: 0,
              },
              "ul > li:before": {
                content: `"•"`,
                fontSize: 2,
                pr: 2,
              },
            }}
          />
          <div
            sx={{
              display: "flex",
              width: ["100%", "60%"],
              flexDirection: "column",
              mt: [4, 7],
            }}
          >
            <div
              sx={{
                display: "grid",
                gridTemplateColumns: ["auto", "repeat(2, 1fr)"],
                gridGap: 4,
                order: secondaryCta && [1, 0],
              }}
            >
              <Button
                onClick={() => {
                  Object.assign(document.createElement("a"), {
                    href: cta.targetUrl,
                  }).click()
                }}
                sx={{
                  width: ["100%", "auto"],
                  maxWidth: "100%",
                  lineHeight: "16px",
                }}
              >
                {cta.label}
              </Button>

              {secondaryCta && (
                <Button
                  variant="outline"
                  onClick={() => {
                    if (isSecondaryCtaYoutube) {
                      setDialogYoutube(true)
                    } else {
                      Object.assign(document.createElement("a"), {
                        target: "_blank",
                        href: secondaryCta.targetUrl,
                      }).click()
                    }
                  }}
                >
                  {isSecondaryCtaYoutube && (
                    <Icon
                      icon={playIcon}
                      size="sm"
                      sx={{
                        mr: 2,
                      }}
                    />
                  )}
                  {secondaryCta.label}
                </Button>
              )}
            </div>
            {ctaDescription && (
              <div
                sx={{
                  my: [1, 2],
                  fontSize: 1,
                  textAlign: "center",
                  width: ["100%", "calc(50% - 8px)"],
                }}
              >
                {ctaDescription}
              </div>
            )}
          </div>
        </div>
      </Container>
      {dialogYoutube && (
        <Dialog
          aria-label={secondaryCta.label}
          onDismiss={() => setDialogYoutube(false)}
          sx={{
            maxWidth: "calc((100vh - 90px) * 16 / 9)",
          }}
        >
          <YoutubeEmbed url={secondaryCta.targetUrl} autoplay={true} />
        </Dialog>
      )}
    </div>
  )
}

export default HeroTrustpilot

export const heroTrustpilotFragment = graphql`
  fragment HeroTrustpilotFragment on Strapi_ComponentBlocksHeroTrustpilot {
    image {
      url
      imageFile {
        childImageSharp {
          gatsbyImageData(width: 2400, layout: CONSTRAINED)
        }
      }
    }
    smallImage {
      url
      imageFile {
        childImageSharp {
          gatsbyImageData(width: 800, layout: CONSTRAINED)
        }
      }
    }
    heroTrustTitle: title
    heroTrustDescription: description
    cta {
      label
      targetUrl
    }
    heroCTADescription: ctaDescription
    secondaryCta {
      label
      targetUrl
    }
    isSecondaryCtaYoutube
    trustpilotDescription
    backgroundColor
    enableRating
    ratingService
    isImageFullWidth
  }
`
