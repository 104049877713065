/** @jsx jsx */
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import {
  Button,
  Container,
  FakeSelect,
  Heading,
} from "@trueskin-web/components"
import { jsx } from "@trueskin-web/theme"
import i18next, { useTranslation } from "@trueskin-web/translations"

const planDetails = {
  items: [
    {
      description: i18next.t("HowItWorksVisually.Step1.description"),
      iconImage: "questionnaireIcon",
      iconAlt: "add photos",
    },
    {
      description: i18next.t("HowItWorksVisually.Step2.description"),
      iconImage: "addPhotosIcon",
      iconAlt: "add photos",
    },
    {
      description: i18next.t("HowItWorksVisually.Step3.description"),
      iconImage: "doctorTreatmentIcon",
      iconAlt: "doctor treatment",
    },
    {
      description: i18next.t("HowItWorksVisually.Step4.description"),
      iconImage:
        process.env.GATSBY_I18N_LOCALE === "pt-BR"
          ? "treatmentDeliveryFormulaOnlyIcon"
          : "treatmentDeliveryIcon",
      iconAlt: "treatment delivery",
    },
    {
      description: i18next.t("HowItWorksVisually.Step5.description"),
      iconImage: "doctorMessageIcon",
      iconAlt: "doctor message",
    },
  ],
}

const HowItWorksVisually = ({ title, centerText, primaryCta }) => {
  const { t } = useTranslation()

  const data = useStaticQuery(graphql`
    {
      questionnaireIcon: file(relativePath: { eq: "questionnaire-list.png" }) {
        childImageSharp {
          gatsbyImageData(width: 120, layout: CONSTRAINED)
        }
      }
      addPhotosIcon: file(relativePath: { eq: "add-photos.png" }) {
        childImageSharp {
          gatsbyImageData(width: 120, layout: CONSTRAINED)
        }
      }
      doctorMessageIcon: file(relativePath: { eq: "doctor-message.png" }) {
        childImageSharp {
          gatsbyImageData(width: 120, layout: CONSTRAINED)
        }
      }
      doctorTreatmentIcon: file(relativePath: { eq: "doctor-treatment.png" }) {
        childImageSharp {
          gatsbyImageData(width: 120, layout: CONSTRAINED)
        }
      }
      treatmentDeliveryIcon: file(
        relativePath: { eq: "treatment-delivery.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 120, layout: CONSTRAINED)
        }
      }
      treatmentDeliveryFormulaOnlyIcon: file(
        relativePath: { eq: "treatment-delivery-formula-only.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 120, layout: CONSTRAINED)
        }
      }
    }
  `)

  return (
    <div sx={{ mt: 5, mb: 8 }}>
      <Container>
        <Heading as="h2" mb={5} sx={{ fontSize: [null, 6] }}>
          {title}
        </Heading>

        <div
          sx={{
            display: "flex",
            flexDirection: ["column", "row"],
            justifyContent: [null, "center"],
            alignItems: "flex-start",
          }}
        >
          {planDetails.items.map(
            ({ iconImage, iconAlt, description }, index) => (
              <div
                key={index}
                sx={{
                  display: "flex",
                  flexDirection: ["row", "column"],
                  alignItems: ["flex-start", centerText ? "center" : null],
                  textAlign: [null, centerText ? "center" : null],
                  maxWidth: [null, 200],
                  pt: [3, null],
                  p: [null, 3],
                }}
              >
                <GatsbyImage
                  image={getImage(data[iconImage])}
                  alt={iconAlt}
                  sx={{
                    mb: [null, 3],
                    maxWidth: [65, 120],
                  }}
                />
                <div
                  sx={{
                    flex: 1,
                    pl: [4, 0],
                  }}
                >
                  <Heading
                    as="h4"
                    size="sm"
                    sx={{ color: "#005E5D", letterSpacing: 1 }}
                  >
                    {t("HowItWorks.step", { number: index + 1 })}
                  </Heading>

                  <div
                    sx={{
                      lineHeight: [1.2, 1.4],
                    }}
                  >
                    {description}
                  </div>
                </div>
              </div>
            )
          )}
        </div>

        {primaryCta && (
          <div
            sx={{
              display: "flex",
              flexDirection: ["column", "row"],
              justifyContent: "center",
              alignItems: "stretch",
              mt: 5,
            }}
          >
            <FakeSelect />

            <Button
              size="lg"
              onClick={() => {
                Object.assign(document.createElement("a"), {
                  href: primaryCta.targetUrl,
                }).click()
              }}
              sx={{
                ml: [null, 5],
                mt: [4, 0],
              }}
            >
              {primaryCta.label}
            </Button>
          </div>
        )}
      </Container>
    </div>
  )
}

export const howItWorksVisuallyFragment = graphql`
  fragment HowItWorksVisuallyFragment on Strapi_ComponentBlocksHowItWorksVisually {
    title
    backgroundColor
    centerText
    primaryCta {
      label
      targetUrl
    }
  }
`

export default HowItWorksVisually
