/** @jsx jsx */
import { graphql } from "gatsby"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"

import {
  Button,
  Container,
  FakeSelect,
  Heading,
  UserGeneratedHtml,
} from "@trueskin-web/components"
import { jsx } from "@trueskin-web/theme"
import { useTranslation } from "@trueskin-web/translations"

const BlogHero = ({
  categoryLayout,
  title,
  richDescription,
  smallImage,
  image,
  primaryCta,
  onHomepage,
  sxOverride,
  breadcrumbs,
  hideConversionElement,
}) => {
  const { t } = useTranslation()
  return (
    <div sx={{ mt: [5, 7], mb: [9, 10], ...sxOverride }}>
      <Container>
        <div
          sx={{
            position: "relative",
            display: "grid",
            gridTemplateColumns: ["auto", "repeat(12, 1fr)"],
            gap: 5,
            minHeight: categoryLayout ? null : [null, 544],
          }}
        >
          <GatsbyImage
            image={smallImage.imageFile.childImageSharp.gatsbyImageData}
            alt=""
            sx={{
              display: [null, "none"],
              maxHeight: 400,
            }}
          />

          {categoryLayout ? (
            <div
              sx={{
                display: ["none", "flex"],
                flexDirection: "column",
                gridColumn: "span 6",
              }}
            >
              <div
                sx={{
                  position: "relative",
                  flex: 1,
                }}
              >
                <GatsbyImage
                  image={image.imageFile.childImageSharp.gatsbyImageData}
                  alt=""
                  sx={{
                    display: ["none", "block"],
                    position: "absolute !important",
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    zIndex: -1,
                  }}
                />
              </div>

              {breadcrumbs && (
                <div
                  sx={{
                    "> nav": {
                      mb: 0,

                      "> ol": {
                        mb: 0,
                      },
                    },
                  }}
                >
                  {breadcrumbs}
                </div>
              )}
            </div>
          ) : (
            <GatsbyImage
              image={image.imageFile.childImageSharp.gatsbyImageData}
              alt=""
              sx={{
                display: ["none", "block"],
                position: "absolute !important",
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                zIndex: -1,
              }}
            />
          )}

          <div
            sx={{
              display: [null, "flex"],
              flexDirection: [null, "column"],
              justifyContent: [
                null,
                categoryLayout ? "flex-start" : "flex-end",
              ],
              gridColumn: categoryLayout
                ? [null, "span 6"]
                : [null, "2 / span 7", "2 / span 8"],
              maxWidth: [null, 546],
              // py: [null, 48],
              // pt: [null, 48],
              pb: categoryLayout ? null : [null, 48],
            }}
          >
            <Heading as="h1" size="xl" mb={[2, 5]}>
              {title}
            </Heading>

            <UserGeneratedHtml
              html={richDescription}
              sx={{ mt: 0, mb: [5, 5], fontSize: 3 }}
            />

            <div
              sx={{
                mb: 5,
              }}
            >
              <StaticImage
                src="../images/badge-arzten-apothekern.png"
                alt={t("BlogHero.imageDescription")}
                width={100}
              />
            </div>

            <div
              sx={{
                display: "flex",
                flexDirection: ["column", "row"],
                alignItems: "stretch",
              }}
            >
              {!hideConversionElement && (
                <FakeSelect
                  sx={{
                    display: "flex",
                    flexGrow: 1,
                    flexBasis: [null, "30%", "50%"],
                  }}
                />
              )}

              <Button
                size="lg"
                onClick={() => {
                  Object.assign(document.createElement("a"), {
                    href: primaryCta.targetUrl,
                  }).click()
                }}
                {...(onHomepage && {
                  id: "hero-cta",
                })}
                sx={{
                  ml: [null, 5],
                  mt: [4, 0],
                }}
              >
                {primaryCta.label}
              </Button>
              {/* {secondaryCta && (
                <Button
                  variant="outline"
                  as={Link}
                  href={secondaryCta.targetUrl}
                  {...(onHomepage && {
                    id: "hero-secondary-cta",
                  })}
                >
                  {secondaryCta.label}
                </Button>
              )} */}
            </div>
          </div>
        </div>

        {breadcrumbs && (
          <div sx={{ display: ["block", "none"] }}>{breadcrumbs}</div>
        )}
      </Container>
    </div>
  )
}

export default BlogHero

export const blogHeroFragment = graphql`
  fragment BlogHeroFragment on Strapi_ComponentBlocksBlogHero {
    title
    richDescription
    smallImage {
      url
      imageFile {
        childImageSharp {
          gatsbyImageData(width: 800, aspectRatio: 1.27, layout: CONSTRAINED)
        }
      }
    }
    image {
      url
      imageFile {
        childImageSharp {
          gatsbyImageData(width: 600, layout: CONSTRAINED)
        }
      }
    }
    primaryCta {
      label
      targetUrl
    }
    badge {
      title
      description
      image {
        url
      }
      width
    }
  }
`
